@font-face {
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 300;
    src: url("./fonts/NotoSans-Light.ttf") format("opentype");
}
@font-face {
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 400;
    src: url("./fonts/NotoSans-Regular.ttf") format("opentype");
}
@font-face {
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 500;
    src: url("./fonts/NotoSans-Medium.ttf") format("opentype");
}
@font-face {
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 600;
    src: url("./fonts/NotoSans-SemiBold.ttf") format("opentype");
}

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@layer base {
    html {
        @apply text-black text-base;
        overflow: hidden;
    }

    a {
        @apply text-primary hover:underline;
    }

    button:focus {
        @apply outline-none;

        box-shadow: none !important;
    }

    h1 {
        @apply text-2xl; /* 24px */
        @apply font-semibold;
        @apply leading-8 tracking-normal;
    }

    h2 {
        @apply text-xl; /* 22px */
        @apply font-medium;
        @apply leading-7 tracking-normal;
    }

    h2.light {
        @apply text-lg; /* 20px */
        @apply font-normal;
        @apply leading-p140 tracking-normal;
    }

    h3 {
        @apply text-md; /* 16px */
        @apply font-semibold;
        @apply leading-p150 tracking-normal;
    }

    h4 {
        @apply text-base; /* 14 px */
        @apply font-semibold;
        @apply leading-p150 tracking-normal;
    }

    h4.light {
        @apply text-sm; /* 13 px */
        @apply font-medium;
        @apply leading-p140 tracking-normal;
    }

    .p1 {
        @apply text-base; /* 14px */
        @apply font-normal;
        @apply leading-p150 tracking-normal;
    }

    .p1-accent {
        @apply text-base; /* 14px */
        @apply font-medium;
        @apply leading-p150 tracking-normal;
    }

    .p2 {
        @apply text-sm; /* 13px */
        @apply font-normal;
        @apply leading-p150 tracking-normal;
    }

    .p2-accent {
        @apply text-sm; /* 13px */
        @apply font-medium;
        @apply leading-p100 tracking-normal;
    }

    .p3 {
        @apply text-xs; /* 12px */
        @apply font-normal;
        @apply leading-p140 tracking-normal;
    }

    .p4 {
        @apply text-xxs; /* 10px */
        @apply font-normal;
        @apply leading-p150 tracking-normal;
    }

    .p4-accent {
        @apply text-xxs; /* 10px */
        @apply font-medium;
        @apply leading-p150 tracking-normal;
    }

    .p4-table {
        @apply text-xxs; /* 10px */
        @apply font-semibold;
        @apply leading-3 tracking-wider;
    }

    .ui-badge .icon {
        position: relative;
        top: -1px;
    }

    .font-bold {
        @apply antialiased;
    }

    .radio {
        background-image: none !important;
        border-color: white !important;
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"] {
        appearance: none;
    }

    input[type="number"] {
        appearance: textfield;
    }

    .flex-center {
        @apply flex;
        @apply justify-center;
        @apply items-center;
    }
}
.video-js,
.video-js .vjs-tech {
    border-radius: 8px;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    display: none;
}

.rdp {
    margin: 16px;
}
.rdp-caption_label {
    text-transform: capitalize;
    @apply text-md; /* 16px */
    @apply font-semibold;
    @apply leading-p150 tracking-normal;
}
.rdp-head_cell {
    @apply p1-accent;
}
.rdp-day {
    @apply p1;
}
.rdp-day_selected,
.rdp-day_selected:hover {
    @apply bg-primary;
    @apply p1-accent;
}
.rdp-head_row th:nth-child(6),
.rdp-head_row th:nth-child(7),
.rdp-row td:nth-child(6),
.rdp-row td:nth-child(7) {
    @apply text-red;
}
.rdp-dropdown {
    padding: 8px;
    background-color: hsl(0, 0%, 100%);
}
.rdp-dropdown option {
    text-transform: capitalize;
    @apply p1;
}
select.rdp-dropdown > option:checked {
    @apply bg-primary;
    @apply text-white;
}
option:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.12) inset;
}

.scrollbar-hidden::-webkit-scrollbar {
    display: none;
}
.scrollbar-hidden {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.that-circle {
    fill: none;
    stroke-width: 4;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.12);
}
@keyframes progress {
    0% {
        stroke-dasharray: 0 100;
    }
}
.percentage {
    fill: rgb(19, 37, 50);
    font-size: 9px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
}

.view-table,
.view-table tr,
.view-table tr th,
.view-table tr td {
    border: 1px solid rgba(19, 37, 50, 1);
}
.view-table tr th,
.view-table tr td {
    padding: 8px;
}

@keyframes blink_input_opacity_to_prevent_scrolling_when_focus {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

input:focus {
    animation: blink_input_opacity_to_prevent_scrolling_when_focus 0.01s;
}

.nowrap {
    text-wrap: nowrap;
}

.break-anywhere {
    overflow-wrap: anywhere;
}

tbody:before {
    content: "@";
    display: block;
    line-height: 10px;
    text-indent: -99999px;
}

.multi-select input {
    box-shadow: none !important;
}

.viewer {
    color: #333;
    font-size: 14px;
    line-height: 1.6;
    word-wrap: break-word;
}
.viewer > * {
    margin: 14px 0;
}
.viewer *::before {
    display: none;
}
.viewer ul {
    padding: 0 40px;
    list-style: disc;
}
.viewer ol {
    padding: 0 40px;
    list-style: decimal;
}
