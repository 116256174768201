/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable selector-class-pattern */
/* stylelint-disable prettier/prettier */
.filter-range input {
    @apply w-full;
    @apply border-[#E6E9ED];

    border-radius: 8px;
}

.filter-range .react-datepicker {
    @apply border-[#E6E9ED];
    @apply shadow-[0px_8px_20px_rgba(0,0,0,0.12)];

    width: 350px;
    border-radius: 8px;
}

.filter-range .react-datepicker__month-container {
    @apply float-none;
}

.filter-range .react-datepicker__triangle {
    @apply hidden;
}

.filter-range .react-datepicker__header {
    @apply bg-transparent;
    @apply border-b-0;
}

.filter-range .react-datepicker__day-name {
    @apply text-base;
    @apply underline;
    @apply font-medium;

    text-decoration-style: dotted;
}

.filter-range .react-datepicker__day--selected,
.filter-range .react-datepicker__day--in-selecting-range,
.filter-range .react-datepicker__day--in-range {
    @apply bg-primary;
}

.filter-range .react-datepicker__day-names {
    margin: 0.4rem;
}

.filter-range .react-datepicker__day-name {
    width: calc(100% / 8);
}

.filter-range .react-datepicker__day {
    @apply text-base;

    width: calc(100% / 8);
}

@media (min-width: 640px) {
    .custom-scrollbar::-webkit-scrollbar {
        width: 8px;
    }

    .custom-scrollbar::-webkit-scrollbar-thumb {
        background: rgba(79, 89, 96, 0.5);
        border-radius: 4px;
    }

    .tree > div::-webkit-scrollbar {
        width: 8px;
    }

    .tree > div::-webkit-scrollbar-thumb {
        background: rgba(79, 89, 96, 0.5);
        border-radius: 4px;
    }
}
