:root {
    --toastify-toast-width: auto;
    --toastify-color-progress-light: rgba(255 255 255 / 100%);
}

.Toastify__toast-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.Toastify__toast,
.Toastify__toast-body,
.Toastify__toast-container {
    padding: 0;
}

.Toastify__toast {
    margin: 0;
    box-shadow: unset;
    background-color: unset;
}

.Toastify__close-button {
    display: none;
}
