.main {
    background: white;
    @apply min-w-40;
}

.main :global(.tippy-arrow) {
    fill: white;
}

.main[data-placement^="top"] > :global(.tippy-arrow::before) {
    border-top-color: white;
}

.main[data-placement^="bottom"] > :global(.tippy-arrow::before) {
    top: -8px;
    border-bottom-color: white;
}

.main[data-placement^="left"] > :global(.tippy-arrow::before) {
    border-left-color: white;
}

.main[data-placement^="right"] > :global(.tippy-arrow::before) {
    border-right-color: white;
}

.main :global(.tippy-content) {
    @apply p-0 text-black;
}
