.player {
  padding: 16px;
  display: flex;
  width: 100%;
  background-color: #fff;
  border: 1px solid #eaedf3;
  border-radius: 5px;
  align-self: center;

  .controls {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .controlsBtn {
      display: flex;
      align-items: center;
    }

    .playButton,
    .replayButton {
      margin: 0;
      padding: 0;
      display: flex;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      font-family: NotoSans, sans-serif;
      line-height: 100%;
      text-align: center;
      border: none;
      cursor: pointer;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .playButton {
      background-color: #1280ce;
      border-radius: 9999px;

      svg {
        fill: #fff;
      }
    }

    .replayButton {
      background-color: transparent;

      svg {
        fill: #1280ce;
      }
    }
  }

  .bar {
    display: flex;
    width: 100%;
    max-width: 487px;
    align-items: center;
    flex-grow: 1;
    user-select: none;
  }

  .volume {
    display: flex;
    width: 100%;
    max-width: 82px;
    align-items: center;
    user-select: none;

    svg {
      width: 14px;
      height: 14px;
    }
  }

  .barProgress {
    display: flex;
    height: 4px;
    flex: 1;
    align-items: center;
    border-radius: 6px;
    cursor: pointer;

    .barProgressKnob {
      position: relative;
      width: 12px;
      height: 12px;
      background-color: white;
      border: 1px solid #eaedf3;
      border-radius: 9999px;
      box-shadow: 0 8px 20px rgba(#000, 0.12);
    }
  }

  .durationValue {
    margin-right: 8px;
    margin-left: 8px;
  }

  .volumeValue {
    margin-left: 8px;
  }

  .muteIcon {
    cursor: pointer;
  }
}
