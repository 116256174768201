.ui-combo-box__control {
    @apply p-0 font-sans text-base leading-p150 !text-gray-dark;
    @apply !rounded-lg !border-none;
    @apply !bg-background;
    @apply !shadow-none;
    @apply !flex-nowrap;

    cursor: pointer !important;
}

.ui-combo-box__control:focus-within {
    @apply !border-none !shadow-none;
}

.border-danger .ui-combo-box__control {
    @apply !border-red;
}

.ui-combo-box__placeholder {
    @apply !text-gray;
}

.ui-combo-box__menu {
    @apply bg-white !rounded-lg !mt-1.25 !z-20;
    @apply !shadow-[0px_8px_20px_rgba(0,0,0,0.12)];
}

.ui-combo-box__menu.css-14h4o58-menu {
    @apply !shadow-[0px_-4px_20px_rgba(0,0,0,0.1)];
}

.ui-combo-box__menu-list {
    @apply !overflow-x-hidden;
}

.custom-scroll .ui-combo-box__menu-list::-webkit-scrollbar {
    width: 8px;
}

.custom-scroll .ui-combo-box__menu-list::-webkit-scrollbar-thumb {
    background: rgb(79 89 96 / 50%);
    border-radius: 4px;
}

.ui-combo-box__option {
    @apply hover:cursor-pointer 2xl:!px-6 2xl:!py-3 2xl:!text-md;
}

.ui-combo-box__option--is-focused {
    @apply !bg-background-light;
}

.ui-combo-box__option--is-selected {
    @apply !bg-blue-lightest;
    @apply !text-black;
}

.ui-combo-box__input-container {
    @apply !my-0 !p-0 !text-gray-icon;
}

.ui-combo-box__value-container--stacked .ui-combo-box__input-container {
    @apply absolute left-0 right-0 z-0 top-1/2 -translate-y-[75%];
}

.ui-combo-box__value-container--stacked.ui-combo-box__value-container--has-value .ui-combo-box__input-container {
    @apply left-4.5 -translate-y-[50%];
}

.ui-combo-box__control .flex {
    @apply shrink-0;
}

.ui-combo-box__control:hover .ui-combo-box__input-container {
    @apply !text-gray-dark;
}

.ui-combo-box__dropdown-indicator svg {
    @apply h-3.5 w-3.5 text-black;
}

.ui-combo-box__dropdown-indicator .sized svg {
    @apply h-full w-full;
}

.ui-combo-box__dropdown-indicator .options-list {
    @apply -translate-y-0.5 -translate-x-1;
}

.ui-combo-box__dropdown-indicator .options-list svg {
    @apply h-5 w-4.5 text-black;
}

.ui-combo-box--is-disabled svg {
    @apply text-gray;
}

.ui-combo-box__indicator-separator {
    @apply hidden;
}

.ui-combo-box__dropdown-indicator {
    @apply mt-1;
}

.ui-combo-box__menu-portal {
    z-index: 99999999 !important;
    right: 0;
}

.ui-combo-box__input:focus {
    box-shadow: none !important;
}

.ui-combo-box__value-container {
    @apply !px-4.5 !py-2.4 2xl:!pl-4.5 2xl:!py-3 !text-ellipsis !whitespace-nowrap !overflow-hidden h-10 2xl:h-12.5 2xl:text-md;
}

.ui-combo-box__value-container input {
    @apply absolute !w-auto;
}

.ui-combo-box__value-container--has-value {
    @apply gap-2.5;
}

.ui-combo-box__single-value {
    @apply inline-block align-middle;
}

.one-row .ui-combo-box__value-container {
    @apply !flex-nowrap;
}

.ui-combo-box__multi-value {
    /* stylelint-disable-next-line max-line-length */
    @apply !my-0 !mx-0 !text-sm !leading-p150 !bg-blue-gray !rounded-2sm !inline-flex !align-middle !z-10 2xl:!text-base 2xl:!leading-p200;
}

.one-row .ui-combo-box__multi-value {
    @apply !shrink-0 !mb-2;
}

.ui-combo-box__multi-value__label {
    @apply !py-1 !text-sm !h-7 2xl:!py-[5px] 2xl:!pl-4 2xl:!text-md 2xl:!h-auto;
}

.ui-combo-box__value-container.ui-combo-box__value-container--is-multi.ui-combo-box__value-container--has-value {
    @apply !py-1.5 !pl-1.5;
}

.ui-combo-box__value-container.ui-combo-box__value-container--is-multi.ui-combo-box__value-container--has-value.ui-combo-box__value-container--focused {
    @apply !pl-4.5;
}

.ui-combo-box__dropdown-indicator i {
    @apply !scale-75 !fill-blue-drk 2xl:!scale-100;
}

.ui-combo-box__multi-value__remove i {
    @apply p-1;
}

.ui-combo-box__multi-value__remove--stacked i {
    @apply !px-0;
    @apply !w-[22px];
}

.ui-combo-box__multi-value__remove:hover svg {
    @apply bg-blue rounded-full fill-background;
}

.ui-combo-box__multi-value__remove:hover {
    @apply !bg-blue-gray !text-black;
}

.ui-combo-box__multi-value__remove.ui-combo-box__multi-value__remove--transparent:hover {
    @apply !bg-transparent;
}

.ui-combo-box__multi-value__remove.ui-combo-box__multi-value__remove--transparent:hover svg {
    @apply bg-blue rounded-full !fill-white;
}

.ui-combo-box__multi-value {
    @apply !shrink-0;
}

.ui-combo-box .ui-combo-box__control--menu-is-open {
    outline-width: 1px !important;
    outline-style: solid !important;
    outline-color: #0084e2 !important;
}
