/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable selector-class-pattern */
/* stylelint-disable prettier/prettier */
.custom-datepicker input {
    @apply w-full;
    @apply border-gray;

    @apply focus:border-primary;
    @apply focus:ring-0;
    @apply focus:ring-primary;

    border-radius: 8px;
}

.custom-datepicker input.react-datepicker-ignore-onclickoutside {
    @apply border-primary;
    @apply ring-0;
    @apply ring-primary;
}

.custom-datepicker .react-datepicker {
    @apply border-[#E6E9ED];
    @apply shadow-[0px_8px_20px_rgba(0,0,0,0.12)];

    width: 350px;
    border-radius: 8px;
}

.custom-datepicker .react-datepicker__month-container {
    @apply float-none;
}

.custom-datepicker .react-datepicker__triangle {
    @apply hidden;
}

.custom-datepicker .react-datepicker__header {
    @apply bg-transparent;
    @apply border-b-0;
}

.custom-datepicker .react-datepicker__day-name {
    @apply text-base;
    @apply underline;
    @apply font-medium;

    text-decoration-style: dotted;
}

.custom-datepicker .react-datepicker__day--selected,
.custom-datepicker .react-datepicker__day--in-selecting-range,
.custom-datepicker .react-datepicker__day--in-range {
    @apply bg-primary;
}

.react-datepicker__day--disabled .day--weekend {
    @apply opacity-50;
}

.custom-datepicker .react-datepicker__day-names {
    margin: 0.4rem;
}

.custom-datepicker .react-datepicker__day-name {
    width: calc(100% / 8);
}

.custom-datepicker .react-datepicker__day {
    @apply text-base;

    width: calc(100% / 8);
}
