.ck-editor__editable_inline {
    height: var(--ckh, 200px);
    max-height: var(--ckh, 200px);
}

.cke_maximized {
    height: 100vh !important;
}
.cke_maximized .cke_contents {
    height: 100% !important;
}

.cke_dialog_contents div[name="info"] tbody:before {
    display: none;
}
.cke_dialog_contents div[name="info"] tbody input[type="checkbox"] {
    width: 10px;
    height: 10px;
    border: 1px solid #bcbcbc;
}
.cke_dialog_contents div[name="info"] tbody input[type="radio"] {
    width: 10px;
    height: 10px;
    border: 1px solid #bcbcbc;
    border-radius: 10px;
}
.cke_dialog_contents div[name="Upload"] tbody tr:nth-child(2) {
    display: none;
}
.cke_top .cke_button__maximize {
    position: absolute;
    top: 45px;
    right: 17px;
}
.cke_top .cke_toolbox .cke_toolbar .cke_button {
    padding: 4px !important;
}
.cke_top .cke_toolbox .cke_toolbar .cke_button,
.cke_top .cke_toolbox .cke_toolbar .cke_button.cke_button_disabled:hover {
    border: 1px solid transparent;
}
.cke_top .cke_toolbox .cke_toolbar .cke_button.cke_button_on,
.cke_top .cke_toolbox .cke_toolbar .cke_button:hover {
    border: 1px solid #bcbcbc;
}
@media screen and (max-width: 1200px) {
    .cke_top .cke_button__maximize {
        top: 79px;
    }
}
@media screen and (max-width: 862px) {
    .cke_top .cke_button__maximize {
        top: 114px;
    }
}
@media screen and (max-width: 740px) {
    .cke_top .cke_button__maximize {
        top: 147px;
    }
}
@media screen and (max-width: 718px) {
    .cke_top .cke_button__maximize {
        top: 182px;
    }
}
@media screen and (max-width: 669px) {
    .cke_top .cke_button__maximize {
        top: 217px;
    }
}
@media screen and (max-width: 639px) {
    .cke_top .cke_button__maximize {
        top: 79px;
    }
}
@media screen and (max-width: 450px) {
    .cke_top .cke_button__maximize {
        top: 114px;
    }
}
